import React from 'react';

import { sanitize as clientSideSanitize } from '@helsenorge/framework-utils/trusted-types';

import { isServerSide } from '../../_helpers/ssr';

/**
 * Sanitizer HTML client side. Obs! Gjør ingen sjekk eller sanitize ved server side rendering.
 * @param html som skal sanitizes
 * @returns sanitizet html (hvis vi er client side)
 */
export const sanitize = (html: string): string => (isServerSide() ? html : clientSideSanitize(html));

interface SafeHTMLProps extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'className' | 'aria-live'> {
  children?: React.ReactNode;
  /** Tekst som vil bli sanitized og rendret inn med dangerouslySetInnerHTML */
  html: string;
  /** Setter data-testid-attributt */
  testId?: string;
}

const SafeHTML: React.FC<SafeHTMLProps> = ({ html, testId, className, children, ...rest }) => {
  return (
    // eslint-disable-next-line react/no-danger
    <div className={className} data-testid={testId} dangerouslySetInnerHTML={{ __html: sanitize(html) }} {...rest}>
      {children}
    </div>
  );
};

export default SafeHTML;
